export interface WORK_ITEM_SUMMARY {
  status: string | null;
  nodeStatus: string | null;
  startTime: string | null;
  endTime: string | null;
  userId: string | null;
  executionId?: string | null;
  filePath?: string | null;
  scheduleType: string;
}

export enum EXECUTION_STATUS {
  succeed = "SUCCEEDED",
  failed = "FAILED",
  running = "RUNNING",
  submitFailed = "SUBMIT_FAILED",
  initialization = "INITIALIZATION",
  pending = "PENDING",
  aborted = "ABORTED",
  aborting = "CANCEL_STARTED",
  timedout = "TIMED_OUT",
  UnKnown = "NA",
}

export enum EXECUTION_REPORT_STATUS {
  normal = "normal",
  nonExistent = "non-existent",
  noPermission = "no permission",
}
